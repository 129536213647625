



































































































































































/* eslint-disable */
import { api_login } from "@/api";
import { ElForm } from "element-ui/types/form";
import { Vue, Component } from "vue-property-decorator";
import { OtherModule } from "@/store/other";
import PhoneSend from "../components/phoneSend.vue";
@Component({
  components: {
    PhoneSend,
  },
})
export default class extends Vue {
  validatePhone(rule: any, value: string, callback: any) {
    const reg = /^(\d){3}-(\d){4}-(\d){4}$/;
    if (!reg.test(value)) {
      callback(new Error("정확한 휴대폰번호를 입력해주세요."));
    } else {
      callback();
    }
  }
  rules = {
    phone: [{ required: true, validator: this.validatePhone }],
    code: [{ required: true, message: "인증번호를 입력해 주세요." }],
  };
  info = {
    phone: "",
    code: "",
  };
  user_info = {
    username: "",
    create_time: "",
  };
  btn_loadding = {
    send: false,
  };

  changePhone(res: any) {
    if (res.length > 8) {
      if (res.slice(3, 4) === "-" && res.slice(8, 9) === "-") {
        this.info.phone = res;
      } else if (res.slice(3, 4) === "-") {
        this.info.phone = res.slice(0, 8) + "-" + res.slice(8);
      } else {
        this.info.phone =
          res.slice(0, 3) + "-" + res.slice(3, 7) + "-" + res.slice(7);
      }
    } else if (res.length > 3) {
      if (res.slice(3, 4) === "-") {
        this.info.phone = res;
      } else {
        this.info.phone = res.slice(0, 3) + "-" + res.slice(3);
      }
    } else {
      this.info.phone = res;
    }
  }

  async submit() {
    await (this.$refs["form"] as ElForm).validate();
    this.user_info = await api_login.find_account(this.info);
    this.step = 2;
  }

  step = 1;

  get is_mobile(): boolean {
    return OtherModule.is_mobile;
  }
}
