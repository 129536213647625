




import { api_login } from "@/api";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class extends Vue {
	@Prop({required:true,type:String})
	phone!:string

	@Prop({ default:"small",type:String })
	size !:string

	@Prop({ default:"인증번호 발송",type:String })
	content !:string

	time = 0

	Interval_timer:number | null = null

	@Watch("phone")
	changePhone(){
		this.time = 0
		if(this.time <= 0){
			if(this.Interval_timer === null){
				return
			}
			clearInterval(this.Interval_timer)
		}
	}

	async send_tel_pass() {
		const reg = /^(\d){3}-(\d){4}-(\d){4}$/
		if(!reg.test(this.phone)) {
			return this.$message.error("정확한 휴대폰번호를 입력해주세요.")
		}
        this._loading = true;
		await api_login.send_sms({
			phone: this.phone,
		}).finally(()=>{
			this._loading = false;
		})
		this.time = 180
		this.Interval_timer = setInterval(()=>{
			this.time--
			if(this.time <= 0){
				if(this.Interval_timer === null){
					return
				}
				clearInterval(this.Interval_timer)
			}
		},1000)
		this.$message.success("발송 되었습니다. 인증번호를 입력해 주세요.");
	}


	destroyed(){
		if(this.Interval_timer === null){
			return
		}
		clearInterval(this.Interval_timer)
	}
}
